@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/utilities';
@import 'styles/mixins/fonts-2024';
@import 'styles/colors-2024';

// Entire modal container (including background)
.modal_content_container {
	background-color: #fff;
	position: relative;
	color: #000;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	margin: 0;
	line-height: 1.4;
	height: 100%;
	width: 100%;
	@include minmax(450px, 599px) {
		overflow: auto;
	}

	@include sm-up {
		display: flex;
		overflow-x: hidden;
		overflow-y: auto;
		width: 360px;
		height: auto;
		padding: 0;
		justify-content: space-around;
		margin: 0 20px;
	}

	p {
		color: #000;
		margin-top: 0;
	}
}

.t1ab .modal_content_container {
	@include sm-up {
		width: 100vw;
		height: 100dvh;
		margin: 0;
	}
}

.close_button {
	position: absolute;
	top: 14px;
	right: 14px;
	z-index: 100;
	cursor: pointer;
	border: none;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 12px;
	}
}

.cap_container {
	margin: 20px 0;
	padding: 0 20px;
}

.info_container {
	color: black;

	h4 {
		font-size: 20px;
		text-align: center;
		line-height: 1.4;
		margin: 0;
	}

	h3 {
		font-size: 48px;
		text-align: center;
		padding: 15px;
		margin: 0;
	}

	p {
		font-size: 14px;
		text-align: center;
		margin: 0 auto 16px;
	}
}

.modal_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	@include xs-only {
		height: 100%;

		> div {
			min-height: 100dvh;
			height: 100%;
		}
	}
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	text-align: center;
	width: 100%;

	input,
	button {
		width: 100%;
	}

	input {
		padding: 15px;
		font-size: 16px;
		border: 1px solid gray !important;
		margin: 10px 0 24px;
	}

	p {
		color: $error;
		font-size: 14px;
		margin: 0px;
	}

	p.sms_disclaimer {
		color: #000;
		font-size: 10px;
		line-height: 12px;
		opacity: 0.4;
	}
}

div .completion_message {
	padding: 10px;
	margin: 10px;
}

.promoImg {
	width: 100%;
	margin: 0;
	border-radius: 0 !important;
}

.promoSubtitle {
	font-weight: bold !important;
	font-size: 16px;
	margin: 24px 0;
	line-height: 1.4;
}

.promoDescription {
	margin: 0 !important;
}

p.disclaimer {
	text-align: center;
	font-size: 11px;
	line-height: 1.4;
	color: #8e9199;
	margin-top: 25px;
}

.button.button {
	margin: auto;
	text-align: center;
	width: 100%;
	font-size: 14px;
	text-decoration-line: underline;

	margin-top: 10px;

	color: #8d9098;
}

.dont_want_button.dont_want_button {
	text-align: center;
	width: 100%;
	font-size: 14px;
	text-decoration-line: underline;

	color: #8d9098;

	margin: 12px auto 18px;
}

div.root {
	display: grid;
	grid-template-columns: 1fr 1fr;

	color: $text-black;

	@include sm-down {
		height: 100dvh;
		grid-template-columns: 1fr;
	}

	@include md-up {
		.image_container {
			height: 100dvh;

			img {
				height: 100%;
				max-width: 100%;
				object-fit: cover !important;
				object-position: center;
				width: 100%;
			}
		}
	}

	.image_container {
		> picture {
			width: 100%;
			height: 100%;
		}

		img {
			max-width: 100%;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: top center;
		}
	}

	.cap_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 400px;
		margin: 15px auto;

		@include sm-down {
			padding: 0 40px;
		}
	}

	.cap_container_quiz {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		max-width: 490px;

		.logo {
			margin-bottom: unset;
			width: 95px;

			@include sm-down {
				display: none;
			}
		}

		.info_container {
			margin-bottom: 1.25rem;
		}

		.info_container_ecap {
			display: flex;
			flex-direction: column;
			gap: 1.25rem;
			margin-bottom: 1.75rem;
		}

		.promoDescription {
			max-width: 30ch;
		}

		.eCapPromoDescription {
			@include sm-down {
				width: 30ch;
			}
		}
		.suptitle {
			@include md-up {
				margin-top: 4rem;
			}

			margin-bottom: 0.75rem;
		}
		@include sm-down {
			max-width: 318px;
			margin: 0 auto;
			height: 100%;
			padding: 0;
			padding-top: 2.5rem;
		}
		h4 {
			margin-bottom: 1.5rem;
			@include sm-down {
				@include headline6;
				margin-bottom: 1rem;
			}
		}
	}
	.suptitle {
		color: $brand-eight-blue;
		letter-spacing: 2px;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 1rem;
		margin-top: 15px;
	}

	.promoDescription {
		color: #636363;
	}

	.logo {
		margin-bottom: 30px;
		width: 95px;

		@include sm-down {
			display: none;
		}
	}

	.email_input input {
		border: none !important;
		border-bottom: 1px solid #636363 !important;
		font-size: 14px;
		font-family: var(--neue-montreal), sans-serif;
	}
}

div.root_quiz {
	@include sm-up {
		grid-template-columns: 1fr 1fr;
	}

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: 242px auto;

		.image_container {
			max-height: 242px;
			height: 100%;

			img {
				max-height: 242px;
				max-width: 100%;
				object-fit: cover !important;
				object-position: top center;
				width: 100%;
			}
		}
	}
}

.ecap_container {
	display: flex;
	flex-direction: column;
}

.promoTitle {
	max-width: 18ch;
}
